export default {
  AUTHENTICATION: {
    NEED_LOGIN: 'Please sign in to add item to your cart',
    LOGIN: 'Sign In',
    REGISTER: 'Sign Up',
    PASSWORD_NOT_MATCH: 'The password confirmation does not match',
    EMAIL_NOT_VALID: 'Invalid email address',
    ACCOUNT_CREATED:
      'Account has been created. You can login using the sign in page.'
  }
}
