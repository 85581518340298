const controllerName = 'dashboard'

function RevenueByMonth() {
  return window.axios.get(`api/${controllerName}/revenue-by-month`)
}

function YearlyRevenue() {
  return window.axios.get(`api/${controllerName}/yearly-revenue`)
}

function TotalRevenue() {
  return window.axios.get(`api/${controllerName}/total-revenue`)
}

function MostPurchasedProducts(type) {
  return window.axios.get(
    `api/${controllerName}/most-purchased-products?reportType=${type}`
  )
}

function DashboardSummary() {
  return window.axios.get(`api/${controllerName}/dashboard-summary`)
}

function NewInvoices() {
  return window.axios.get(`api/${controllerName}/new-invoices`)
}

function UnprocessedInvoices() {
  return window.axios.get(`api/${controllerName}/unprocessed-invoices`)
}

function NewCustomer() {
  return window.axios.get(`api/${controllerName}/new-registrations`)
}

export {
  RevenueByMonth,
  YearlyRevenue,
  TotalRevenue,
  MostPurchasedProducts,
  DashboardSummary,
  NewInvoices,
  UnprocessedInvoices,
  NewCustomer
}
