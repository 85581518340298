<template>
  <div id="input-receipt" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Input Receipt Number</h2>
        </div>
        <div class="modal-body p-10 text-center">
          <div class="mt-3">
            <label for="input-receipt-number" class="form-label"
              >Receipt Number</label
            >
            <input
              @keydown.enter="putReceiptNumber"
              id="input-receipt-number"
              type="text"
              class="form-control"
              v-model="receiptNumber"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary mr-2"
            @click="putReceiptNumber"
          >
            Save
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PutReceiptNumber } from '@/core/services/entities/invoices.service'
import { useToast } from 'vue-toastification'
import { mapActions } from 'vuex'

export default {
  inject: ['emitter'],
  props: {
    afterSubmit: {
      type: Function,
      default: () => {}
    },
    invoiceId: {
      type: String,
      required: true
    },
    inputReceiptNumber: {
      type: String,
      required: true
    }
  },
  watch: {
    inputReceiptNumber() {
      this.receiptNumber = this.inputReceiptNumber
    }
  },
  data() {
    return {
      receiptNumber: ''
    }
  },
  methods: {
    ...mapActions('app', ['ChangeLoadingState']),
    async putReceiptNumber() {
      try {
        const toast = useToast()
        this.ChangeLoadingState(true)
        const response = await PutReceiptNumber(
          this.invoiceId,
          this.receiptNumber
        )
        if (response.status === 200) {
          if (response.data.isSuccess) {
            toast.success('Receipt number saved.', {
              timeout: 2000
            })
            this.afterSubmit()
            this.emitter.emit('refresh_table')
            cash('#input-receipt').modal('toggle')
          } else {
            toast.error(response.data.message, {
              timeout: 2000
            })
          }
        } else {
          toast.error(this.$t('MESSAGES.FAILED_PROCESS_ADMIN'), {
            timeout: 2000
          })
        }
      } catch (e) {}

      this.ChangeLoadingState(false)
    }
  }
}
</script>

<style>
</style>