export default {
  FOOTER: {
    GETTOKNOW: 'Get to know',
    CONTACTUS: 'Contact Us',
    ABOUTUS: 'About Us',
    FAQ: 'FAQ',
    TNC: 'Terms and Condition',
    RNE: 'Return and Exchange',
    JOINTITLE: 'Join our Family',
    JOINTEXT: 'Lets join to be a Hikarusa reseller',
    JOINBTNTEXT: 'Register Hikarusa Reseller',
    PAYMENT: 'Payment',
    COPYRIGHT: '&copy; 2022 - All right reserved. Web designed and hosted by Rain.Digital'
  }
}
