<template>
  <div class="col-span-12 xl:col-span-4 mt-6">
    <div class="intro-y flex items-center h-10">
      <h2 class="text-lg font-medium truncate mr-5">{{ title }}</h2>
    </div>
    <div class="mt-5">
      <div v-for="item in invoices" :key="item.id" class="intro-y">
        <router-link
          :to="{
            name: 'adminInvoiceDetail',
            params: { id: item.id }
          }"
        >
          <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
            <div class="ml-4 mr-auto">
              <div class="font-medium">
                {{ item.documentNo }}
              </div>
              <div class="text-gray-600 text-xs mt-0.5">
                {{ item.createdAt }} / {{ item.invoiceDetails.length }} items
              </div>
            </div>
            <div
              class="
                py-1
                px-2
                rounded-full
                text-xs
                bg-theme-6
                dark:bg-theme-20
                text-white
                cursor-pointer
                font-medium
              "
            >
              {{ item.status }}
            </div>
          </div>
        </router-link>
      </div>
      <router-link
        :to="{ name: 'adminInvoices' }"
        class="
          intro-y
          w-full
          block
          text-center
          rounded-md
          py-4
          border border-dotted border-theme-32
          dark:border-dark-5
          text-theme-33
          dark:text-gray-600
        "
        >View More</router-link
      >
    </div>
  </div>
</template>

<script>
import {
  NewInvoices,
  UnprocessedInvoices
} from '@/core/services/entities/dashboard.service'
export default {
  props: {
    type: {
      required: true
    }
  },
  data() {
    return {
      title: '',
      invoices: []
    }
  },
  methods: {
    async DoGetInvoices() {
      if (this.type === 'new') {
        this.title = 'New Invoices'
        const response = await NewInvoices()
        if (response.status === 200 && response.data.isSuccess) {
          this.invoices = response.data.data
        }
      } else {
        this.title = 'Unprocessed Invoices'
        const response = await UnprocessedInvoices()
        if (response.status === 200 && response.data.isSuccess) {
          this.invoices = response.data.data
        }
      }
    }
  },
  mounted() {
    this.DoGetInvoices()
  }
}
</script>

<style>
</style>
