<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">&nbsp;</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          v-if="invoice.statusEnum === 1 && invoice.statusEnum === 2"
          @click="showReceiptModal"
          class="btn btn-primary shadow-md mr-2"
        >
          Input Receipt Number
        </button>
        <button
          @click="ProcessInvoice"
          v-if="invoice.statusEnum === 1"
          class="btn btn-primary shadow-md mr-2"
        >
          Process Invoice
        </button>
      </div>
      <div class="dropdown ml-auto sm:ml-0">
        <button
          class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300"
          aria-expanded="false"
        >
          <span class="w-5 h-5 flex items-center justify-center">
            <MoreHorizontalIcon class="w-4 h-4" />
          </span>
        </button>
        <div class="dropdown-menu w-40">
          <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
            <a
              @click="printInvoice(true)"
              class="
                cursor-pointer
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                bg-white
                dark:bg-dark-1
                hover:bg-gray-200
                dark:hover:bg-dark-2
                rounded-md
              "
            >
              <FileIcon class="w-4 h-4 mr-2" /> Print
            </a>
            <a
              @click="printInvoice(false)"
              class="
                cursor-pointer
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                bg-white
                dark:bg-dark-1
                hover:bg-gray-200
                dark:hover:bg-dark-2
                rounded-md
              "
            >
              <FileIcon class="w-4 h-4 mr-2" /> Print Without Price
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Invoice -->
    <div class="intro-y box overflow-hidden mt-5" id="invoice-wrapper">
      <div
        class="
          border-b border-gray-200
          dark:border-dark-5
          text-center
          sm:text-left
        "
      >
        <div class="px-5 py-10 sm:px-20 sm:py-20">
          <div class="text-theme-25 dark:text-theme-22 font-semibold text-3xl">
            INVOICE
          </div>
          <div class="mt-2">
            <span class="font-medium">{{ invoice.documentNo }}</span>
          </div>
          <div class="mt-1">{{ invoice.createdAt }}</div>
        </div>
        <div
          class="flex flex-col lg:flex-row px-5 sm:px-20 pt-10 pb-10 sm:pb-20"
        >
          <div>
            <div class="text-base text-gray-600">Client Details</div>
            <div
              class="text-lg font-medium text-theme-25 dark:text-theme-22 mt-2"
            >
              {{ invoice.customer.fullname }}
            </div>
            <div class="mt-1">{{ invoice.customer.phoneNumber }}</div>
            <div class="mt-1">
              {{ invoice.address }},<br />
              {{ invoice.city.name }} {{ invoice.province.name }}<br />
              {{ invoice.postalCode }}<br /><br />
              Notes: {{ invoice.notes }}
            </div>
          </div>
          <div
            v-if="!isPrintMode"
            :class="[
              'lg:text-right mt-10 lg:mt-0 lg:ml-auto',
              this.isPrintMode ? 'hidden' : ''
            ]"
          >
            <div class="text-base text-gray-600">Invoice Information</div>
            <div class="mt-1">
              Status :
              <span
                class="text-right border-b dark:border-dark-5 w-32 font-medium"
                >{{ invoice.status }}</span
              >
            </div>
            <div class="mt-1">
              Process Date :
              <span
                class="text-right border-b dark:border-dark-5 w-32 font-medium"
                >{{ invoice.processedDate }}</span
              >
            </div>
            <div class="mt-1" v-if="invoice.receiptNumber">
              Receipt Number :
              <span
                class="text-right border-b dark:border-dark-5 w-32 font-medium"
                >{{ invoice.receiptNumber }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="px-5 sm:px-16 py-10 sm:py-20">
        <div class="overflow-x-auto">
          <table class="table">
            <thead>
              <tr>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  DESCRIPTION
                </th>
                <th
                  class="
                    border-b-2
                    dark:border-dark-5
                    text-right
                    whitespace-nowrap
                  "
                >
                  QTY
                </th>
                <th
                  v-if="includePrice"
                  :class="[
                    'border-b-2',
                    'dark:border-dark-5',
                    'text-right',
                    'whitespace-nowrap',
                    includePrice ? '' : 'hidden'
                  ]"
                >
                  PRICE
                </th>
                <th
                  v-if="includePrice"
                  :class="[
                    'border-b-2',
                    'dark:border-dark-5',
                    'text-right',
                    'whitespace-nowrap',
                    includePrice ? '' : 'hidden'
                  ]"
                >
                  DISCOUNT
                </th>
                <th
                  v-if="includePrice"
                  :class="[
                    'border-b-2',
                    'dark:border-dark-5',
                    'text-right',
                    'whitespace-nowrap',
                    includePrice ? '' : 'hidden'
                  ]"
                >
                  SUBTOTAL
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in invoice.invoiceDetails" :key="item.id">
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{ item.productDetail.product.name }} -
                    {{ item.productDetail.variant.name }}
                    {{ item.productDetail.color.name }}
                    {{ item.productDetail.size.name }}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{ item.productDetail.variant.code }}-{{
                      item.productDetail.color.code
                    }}-{{ item.productDetail.size.code }}
                  </div>
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32">
                  {{ item.quantity }}
                </td>
                <td
                  v-if="includePrice"
                  :class="[
                    'text-right',
                    'border-b',
                    'dark:border-dark-5',
                    'w-32',
                    includePrice ? '' : 'hidden'
                  ]"
                  class=""
                >
                  Rp. {{ numeralFormat(item.price, '0,0[.]00') }}
                </td>
                <td
                  v-if="includePrice"
                  :class="[
                    'text-right',
                    'border-b',
                    'dark:border-dark-5',
                    'w-32',
                    includePrice ? '' : 'hidden'
                  ]"
                >
                  {{ item.discount }}%
                </td>
                <td
                  v-if="includePrice"
                  :class="[
                    'text-right',
                    'border-b',
                    'dark:border-dark-5',
                    'w-32',
                    'font-medium',
                    includePrice ? '' : 'hidden'
                  ]"
                >
                  Rp.
                  {{
                    numeralFormat(
                      (item.price - (item.price * item.discount) / 100) *
                        item.quantity,
                      '0,0[.]00'
                    )
                  }}
                </td>
              </tr>
              <tr
                v-if="invoice.shipmentFee > 0 && !isPrintMode"
                :class="[isPrintMode ? 'hidden' : '']"
              >
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    Shipment Fee {{ invoice.courier }}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{ invoice.durationCode }}-{{ invoice.duration }}
                  </div>
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32">1</td>
                <td class="text-right border-b dark:border-dark-5 w-32">
                  Rp. {{ numeralFormat(invoice.shipmentFee, '0,0[.]00') }}
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32">0%</td>
                <td
                  class="
                    text-right
                    border-b
                    dark:border-dark-5
                    w-32
                    font-medium
                  "
                >
                  Rp. {{ numeralFormat(invoice.shipmentFee, '0,0[.]00') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="px-5 sm:px-20 pb-10 sm:pb-20 flex flex-col-reverse sm:flex-row"
      >
        <div class="text-center sm:text-left mt-10 sm:mt-0">
          <a
            v-if="invoice.xenditId && !isPrintMode"
            target="_blank"
            :href="`https://dashboard.xendit.co/invoice/${invoice.xenditId}`"
            :class="[
              'cursor-pointer',
              'text-lg',
              'text-theme-25',
              'dark:text-theme-22',
              'font-medium',
              'mt-2',
              this.isPrintMode ? 'hidden' : 'block'
            ]"
          >
            See xendit reference
          </a>
        </div>
        <div
          v-if="includePrice"
          :class="[
            'text-center sm:text-right sm:ml-auto',
            includePrice ? '' : 'hidden'
          ]"
        >
          <div class="text-base text-gray-600">Total Amount</div>
          <div
            class="text-xl text-theme-25 dark:text-theme-22 font-medium mt-2"
          >
            Rp. {{ numeralFormat(totalAmount, '0,0[.]00') }}
          </div>
        </div>
      </div>
    </div>
    <!-- END: Invoice -->
  </div>
  <receiptNumberModal
    :afterSubmit="afterReciptSave"
    :inputReceiptNumber="invoice.receiptNumber"
    :invoiceId="id"
  ></receiptNumberModal>
</template>

<script>
import receiptNumberModal from './ReceiptNumberModal.vue'
import { Get, Process } from '@/core/services/entities/invoices.service'
import { useToast } from 'vue-toastification'
import Swal from 'sweetalert2'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    receiptNumberModal: receiptNumberModal
  },
  computed: {
    totalAmount() {
      let totalAmount = 0
      this.invoice.invoiceDetails.forEach(item => {
        totalAmount +=
          (item.price - (item.price * item.discount) / 100) * item.quantity
      })

      totalAmount += this.invoice.shipmentFee
      return totalAmount
    }
  },
  data() {
    return {
      includePrice: true,
      isPrintMode: false,
      invoice: {
        documentNo: '',
        createdAt: '',
        address: '',
        postalCode: '',
        customer: {
          fullname: '',
          email: ''
        },
        province: {
          name: ''
        },
        city: {
          name: ''
        },
        invoiceDetails: [],
        xenditId: '',
        durationCode: '',
        duration: '',
        shipmentFee: 0,
        statusEnum: 99,
        status: '',
        processedDate: ''
      }
    }
  },
  methods: {
    async afterReciptSave() {
      await this.RefreshPage()
    },
    showReceiptModal() {
      cash('#input-receipt').modal('toggle')
    },
    printInvoice(isIncludePrice) {
      const _this = this
      _this.isPrintMode = true
      _this.includePrice = isIncludePrice
      setTimeout(() => {
        // Get HTML to print from element
        const prtHtml = document.getElementById('invoice-wrapper').innerHTML

        // Get all stylesheets HTML
        let stylesHtml = ''
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style')
        ]) {
          stylesHtml += node.outerHTML
        }

        // Open the print window
        const WinPrint = window.open(
          '',
          '',
          'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
        )

        WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`)

        WinPrint.document.close()
        WinPrint.focus()
        WinPrint.print()
        WinPrint.close()
        _this.isPrintMode = false
        _this.includePrice = true
      }, 100)
    },
    async ProcessInvoice() {
      Swal.fire({
        title: 'Confirmation Needed',
        text:
          'Are you sure you want to change this invoice status to Processed?',
        confirmButtonColor: '#2F5AD8',
        cancelButtonColor: '#E5EAF1',
        showCancelButton: true,
        confirmButtonText: 'Process'
      }).then(async result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const toast = useToast()
          const response = await Process(this.id)
          if (response.status === 200) {
            if (response.data.isSuccess) {
              toast.success(this.$t('MESSAGES.INVOICE_PROCESSED'), {
                timeout: 2000
              })
              await this.RefreshPage()
            } else {
              toast.error(response.data.message, {
                timeout: 5000
              })
            }
          } else {
            toast.error(this.$t('MESSAGES.FAILED_PROCESS_ADMIN'), {
              timeout: 5000
            })
          }
        }
      })
    },
    async RefreshPage() {
      const response = await Get(this.id)
      if (response.status === 200 && response.data.isSuccess) {
        this.invoice = response.data.data
      } else {
        const toast = useToast()
        toast.error(this.$t('MESSAGES.FAILED_GET'), {
          timeout: 2000
        })
      }
    }
  },
  async mounted() {
    await this.RefreshPage()
  }
}
</script>
