export default {
  PRODUCTDETAILS: {
    VARIANTS: 'Variants',
    SIZES: 'Sizes',
    COLORS: 'Colors',
    SIZECHART: 'Size Chart',
    PRODUCTRECOMMENDATION: 'Product Recommendation',
    QTY: 'Qty'
  }
}
