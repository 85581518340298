export const CREATE = 'Create'
export const EDIT = 'Edit'
export const SET_ISREADY = 'SetIsReady'
export const SET_ID = 'SetActiveId'

export const baseState = {
  id: '',
  name: '',
  description: ''
}

const state = {
  id: '',
  formModel: baseState,
  isReady: false
}

const mutations = {
  [CREATE](state) {
    state.id = ''
    state.formModel = baseState
    state.isReady = true
  },
  [EDIT](state, payload) {
    state.isReady = true
  },
  [SET_ISREADY](state, payload) {
    state.isReady = payload
  },
  [SET_ID](state, payload) {
    state.id = payload
  }
}

const actions = {
  [CREATE]({ commit }) {
    commit(CREATE)
  },
  [EDIT]({ commit }, payload) {
    commit(SET_ISREADY, false)
    commit(SET_ID, payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
