export default {
  REGISTER_RESELLER: {
    TITLE: 'Become a reseller',
    CONTENT1: `
    <p>Ayo bergabung menjadi bagian dari keluarga Hikarusa Group, banyak kesempatan di kota kamu untuk menjadi reseller resmi dari Hikarusa Group, kami membuka slot untuk menjadi
    Official Store & Official Reseller. Untuk seluruh kota di Indonesia, dan luar negeri, untuk informasi detail mengenai program reseller bisa hubungi Official Distributor/Distributor Resmi Hikarusa Group dibawah ini:</p>
    `,
    SUBTITLE1: 'Silahkan pilih salah satu Distributor Resmi kami',
    SUBTITLE2: 'Semua pengiriman sama dari Bandung'
  }
}
