import { createRouter, createWebHistory } from 'vue-router'
import DashboardOverview1 from '../views/dashboard/Main.vue'
import BaseParent from '@/layouts/BaseParent.vue'
import AdminViewInvoice from '@/views/.admin/Invoices/Detail.vue'
import AdminAdmins from '@/views/.admin/Admins/Index.vue'
import AdminCustomers from '@/views/.admin/Customers/Index.vue'
import AdminInvoice from '@/views/.admin/Invoices/Index.vue'
import AdminProduct from '@/views/.admin/Products/Index.vue'
import AdminBP from '@/views/.admin/BusinessPartners/Index.vue'
import AdminCity from '@/views/.admin/Cities/Index.vue'
import AdminSize from '@/views/.admin/Sizes/Index.vue'
import AdminColor from '@/views/.admin/Colors/Index.vue'
import AdminVariant from '@/views/.admin/Variants/Index.vue'
import AdminCategories from '@/views/.admin/Categories/Index.vue'
import AdminPromoes from '@/views/.admin/Promoes/Index.vue'
import Login from '../views/login/Main.vue'

const routes = [
  {
    path: '/stock-summary',
    name: 'stockSummary',
    component: () => import('@/layouts/StockView.vue')
  },
  {
    path: '/',
    name: 'customerModule',
    component: () => import('@/layouts/CustomerParent.vue'),
    children: [
      {
        name: 'homepage',
        path: '',
        component: () => import('@/views/.customer/pages/Homepage')
      },
      {
        name: 'forgotPassword',
        path: 'forgot-password',
        component: () => import('@/views/.customer/pages/ForgotPassword')
      },
      {
        name: 'activateAccount',
        path: 'activate-account',
        component: () => import('@/views/.customer/pages/ActivateAccount')
      },
      {
        name: 'resetPassword',
        path: 'reset-password',
        component: () => import('@/views/.customer/pages/ResetPassword')
      },
      {
        name: 'aboutUs',
        path: 'about-us',
        component: () => import('@/views/.customer/pages/AboutUs')
      },
      {
        name: 'resellerList',
        path: 'reseller-list',
        component: () => import('@/views/.customer/pages/ResellerList')
      },
      {
        name: 'registerReseller',
        path: 'reseller-register',
        component: () => import('@/views/.customer/pages/RegisterReseller')
      },
      {
        name: 'login',
        path: 'login',
        component: () => import('@/views/.customer/pages/Login')
      },
      {
        name: 'registration',
        path: 'registration',
        component: () => import('@/views/.customer/pages/Registration')
      },
      {
        name: 'contactUs',
        path: 'contact-us',
        component: () => import('@/views/.customer/pages/ContactUs')
      },
      {
        name: 'FAQ',
        path: 'faq',
        component: () => import('@/views/.customer/pages/FAQ')
      },
      {
        name: 'CareManual',
        path: 'care-manual',
        component: () => import('@/views/.customer/pages/CareManual')
      },
      {
        name: 'sizeRecommendation',
        path: 'size-recommendation',
        component: () => import('@/views/.customer/pages/SizeRecommendation')
      },
      {
        name: 'detailsProduct',
        path: 'details-product/:id',
        props: true,
        component: () => import('@/views/.customer/pages/DetailsProduct')
      },
      {
        name: 'shop',
        path: 'shop',
        component: () => import('@/views/.customer/pages/Shop')
      },
      {
        name: 'checkout',
        path: 'checkout',
        component: () => import('@/views/.customer/pages/Checkout')
      },
      {
        name: 'paymentRedirect',
        path: 'payment-redirect',
        component: () => import('@/views/.customer/pages/PaymentRedirect')
      },
      {
        name: 'accountDetail',
        path: 'account-detail',
        component: () => import('@/views/.customer/pages/AccountDetail')
      }
    ]
  },
  {
    path: '/admin',
    component: () => import('@/layouts/side-menu/Main.vue'),
    children: [
      {
        path: '',
        name: 'adminDashboard',
        component: DashboardOverview1
      },
      {
        path: 'products',
        name: 'baseProducts',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminProducts',
            component: AdminProduct
          }
        ]
      },
      {
        path: 'city',
        name: 'baseCities',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminCities',
            component: AdminCity
          }
        ]
      },
      {
        path: 'business-partner',
        name: 'baseBPs',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminBPs',
            component: AdminBP
          }
        ]
      },
      {
        path: 'sizes',
        name: 'baseSizes',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminSizes',
            component: AdminSize
          }
        ]
      },
      {
        path: 'categories',
        name: 'baseCategories',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminCategories',
            component: AdminCategories
          }
        ]
      },
      {
        path: 'promoes',
        name: 'basePromoes',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminPromoes',
            component: AdminPromoes
          }
        ]
      },
      {
        path: 'colors',
        name: 'baseColors',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminColors',
            component: AdminColor
          }
        ]
      },
      {
        path: 'variants',
        name: 'baseVariants',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminVariants',
            component: AdminVariant
          }
        ]
      },
      {
        path: 'invoices',
        name: 'baseInvoices',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminInvoices',
            component: AdminInvoice
          },
          {
            name: 'adminInvoiceDetail',
            path: 'detail/:id',
            props: true,
            component: AdminViewInvoice
          }
        ]
      },
      {
        path: 'admins',
        name: 'baseAdmins',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminAdmins',
            component: AdminAdmins
          }
        ]
      },
      {
        path: 'customers',
        name: 'baseCustomers',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminCustomers',
            component: AdminCustomers
          }
        ]
      }
    ]
  },
  {
    name: 'adminLogin',
    path: '/admin/login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

export default router
