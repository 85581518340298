import { IsValidProp } from '../helper.service'

const controllerName = 'businesspartners'

function GetAll(params) {
  return window.axios.get(`api/${controllerName}`, { params })
}

function Get(id) {
  return window.axios.get(`api/${controllerName}/${id}`)
}

function Put(id, formModel) {
  const formData = new FormData()
  for (const key in formModel) {
    if (key === 'businessPartnerImage') {
      if (formModel[key][0]) {
        formData.append(key, formModel[key][0].file)
      }
    } else if (IsValidProp(formModel[key])) {
      formData.append(key, formModel[key] == null ? '' : formModel[key])
    }
  }

  return window.axios.put(`api/${controllerName}/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  })
}

function Post(formModel) {
  const formData = new FormData()

  for (const key in formModel) {
    if (key === 'businessPartnerImage') {
      if (formModel[key][0]) {
        formData.append(key, formModel[key][0].file)
      }
    } else if (IsValidProp(formModel[key])) {
      formData.append(key, formModel[key] == null ? '' : formModel[key])
    }
  }

  return window.axios.post(`api/${controllerName}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  })
}

function Types() {
  return window.axios.get(`api/${controllerName}/key-value`)
}

function Random(cityId) {
  const params = {
    'isActive.Operator': 4,
    'isActive.Value': true,
    'bpCityId.Operator': 4,
    'bpCityId.Value': cityId
  }
  return window.axios.get(`api/${controllerName}/random`, { params })
}

export { GetAll, Get, Put, Post, Types, Random }
