
<template>
  <div>
    <teleport to="#breadcrumb-wrapper" v-if="isReady">
      <a href="" class="breadcrumb--active">Dashboard</a>
    </teleport>
    <div class="relative">
      <div class="grid grid-cols-12 gap-6 pb-10">
        <div class="col-span-12 xl:col-span-9 2xl:col-span-9 z-10">
          <div class="mt-14 mb-3 grid grid-cols-12 sm:gap-10 intro-y">
            <div
              class="
                col-span-12
                sm:col-span-6
                md:col-span-4
                py-6
                sm:pl-5
                md:pl-0
                lg:pl-5
                relative
                text-center
                sm:text-left
              "
            >
              <div class="text-white text-sm 2xl:text-base font-medium -mb-1">
                Hi {{ currentUser.firstName }},
                <span class="text-theme-34 dark:text-gray-500 font-normal"
                  >welcome back!</span
                >
              </div>
              <div
                class="
                  text-base
                  2xl:text-lg
                  justify-center
                  sm:justify-start
                  flex
                  items-center
                  text-theme-34
                  dark:text-gray-500
                  leading-3
                  mt-14
                  2xl:mt-24
                "
              >
                Application Total Revenue
                <Tippy
                  tag="div"
                  content="Total value of your sales: $158.409.416"
                >
                  <AlertCircleIcon class="w-5 h-5 ml-1.5 mt-0.5" />
                </Tippy>
              </div>
              <div class="2xl:flex mt-5 mb-3">
                <div class="flex items-center justify-center sm:justify-start">
                  <div
                    class="
                      text-white
                      relative
                      text-2xl
                      2xl:text-3xl
                      font-medium
                      leading-6
                      pl-10
                      2xl:pl-10
                    "
                  >
                    <span
                      class="
                        absolute
                        text-xl
                        2xl:text-2xl
                        top-0
                        left-0
                        -mt-1
                        2xl:mt-0
                      "
                      >Rp.</span
                    >
                    {{ numeralFormat(totalRevenue, '0,0[.]00') }}
                  </div>
                </div>
              </div>
              <div
                class="
                  text-base
                  2xl:text-md
                  justify-center
                  sm:justify-start
                  flex
                  items-center
                  text-theme-34
                  dark:text-gray-500
                  leading-3
                  mt-1
                "
              >
                Yearly Profit
                <Tippy
                  tag="div"
                  content="Total value of your sales: $158.409.416"
                >
                  <AlertCircleIcon class="w-5 h-5 ml-1.5 mt-0.5" />
                </Tippy>
              </div>
              <div class="2xl:flex mt-5 mb-3">
                <div class="flex items-center justify-center sm:justify-start">
                  <div
                    class="
                      text-white
                      relative
                      text-xl
                      2xl:text-xl
                      font-medium
                      leading-6
                      pl-10
                      2xl:pl-10
                    "
                  >
                    <span
                      class="
                        absolute
                        text-xl
                        2xl:text-2xl
                        top-0
                        left-0
                        -mt-1
                        2xl:mt-0
                      "
                      >Rp.</span
                    >
                    {{ numeralFormat(yearlyRevenue, '0,0[.]00') }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                row-start-2
                md:row-start-auto
                col-span-12
                md:col-span-4
                py-6
                border-theme-11 border-t
                md:border-t-0 md:border-l md:border-r
                border-dashed
                px-10
                sm:px-28
                md:px-5
                -mx-5
              "
            >
              <div class="flex flex-wrap items-center">
                <div
                  class="
                    flex
                    items-center
                    w-full
                    sm:w-auto
                    justify-center
                    sm:justify-start
                    mr-auto
                    mb-5
                    2xl:mb-0
                  "
                >
                  <div
                    class="
                      text-base
                      2xl:text-lg
                      justify-center
                      sm:justify-start
                      flex
                      items-center
                      text-theme-34
                      dark:text-gray-500
                      leading-3
                    "
                  >
                    Past Revenue
                    <Tippy
                      tag="div"
                      content="6 Previous months of your profit revenue"
                    >
                      <AlertCircleIcon class="w-5 h-5 ml-1.5 mt-0.5" />
                    </Tippy>
                  </div>
                </div>
              </div>
              <PastRevenue v-if="revenueByMonth" :height="267" :data="revenueByMonth" class="mt-8" />
            </div>
            <FavoriteProduct></FavoriteProduct>
          </div>
        </div>
      </div>
      <SummaryReport></SummaryReport>
    </div>
    <div
      class="
        report-box-3 report-box-3--content
        grid grid-cols-12
        gap-6
        xl:-mt-5
        2xl:-mt-8
        -mb-10
        z-40
        2xl:z-10
      "
    >
      <div class="col-span-12 2xl:col-span-9 mb-10">
        <div class="grid grid-cols-12 gap-6">
          <InvoicesDashboard
            :type="'unprocessed'"
            :title="'Unprocessed Invoices'"
          ></InvoicesDashboard>
          <InvoicesDashboard
            :type="'new'"
            :title="'New Invoices'"
          ></InvoicesDashboard>
          <NewSignIns></NewSignIns>
        </div>
      </div>
      <!-- <div class="col-span-12 2xl:col-span-3 relative z-10">
        <div class="2xl:border-l border-theme-31 pb-10 intro-y">
          <div class="2xl:pl-6 grid grid-cols-12 gap-6">
            <div class="col-span-12 md:col-span-6 2xl:col-span-12 mt-3">
              <div class="intro-x flex items-center h-10">
                <h2 class="text-lg font-medium truncate mr-5">Transactions</h2>
              </div>
              <div class="mt-5">
                <div
                  v-for="(faker, fakerKey) in $_.take($f(), 5)"
                  :key="fakerKey"
                  class="intro-x"
                >
                  <div class="box px-5 py-3 mb-3 flex items-center zoom-in">
                    <div
                      class="
                        w-10
                        h-10
                        flex-none
                        image-fit
                        rounded-full
                        overflow-hidden
                      "
                    >
                      <img
                        alt="Tinker Tailwind HTML Admin Template"
                        :src="require(`@/assets/images/${faker.photos[0]}`)"
                      />
                    </div>
                    <div class="ml-4 mr-auto">
                      <div class="font-medium">
                        {{ faker.users[0].name }}
                      </div>
                      <div class="text-gray-600 text-xs mt-0.5">
                        {{ faker.dates[0] }}
                      </div>
                    </div>
                    <div
                      :class="{
                        'text-theme-20': faker.trueFalse[0],
                        'text-theme-21': !faker.trueFalse[0]
                      }"
                    >
                      {{ faker.trueFalse[0] ? '+' : '-' }}${{ faker.totals[0] }}
                    </div>
                  </div>
                </div>
                <a
                  href=""
                  class="
                    intro-x
                    w-full
                    block
                    text-center
                    rounded-md
                    py-3
                    border border-dotted border-theme-32
                    dark:border-dark-5
                    text-theme-33
                    dark:text-gray-600
                  "
                  >View More</a
                >
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  RevenueByMonth,
  YearlyRevenue,
  TotalRevenue
} from '@/core/services/entities/dashboard.service'
import { inject } from 'vue'
import PastRevenue from './Components/PastRevenue.vue'
import NewSignIns from './Components/NewSignIns.vue'
import InvoicesDashboard from './Components/InvoicesDashboard.vue'
import SummaryReport from './Components/SummaryReport.vue'
import FavoriteProduct from './Components/FavoriteProduct.vue'
export default {
  components: {
    PastRevenue: PastRevenue,
    NewSignIns: NewSignIns,
    InvoicesDashboard: InvoicesDashboard,
    SummaryReport: SummaryReport,
    FavoriteProduct: FavoriteProduct
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  data() {
    return {
      isReady: false,
      revenueByMonth: null,
      yearlyRevenue: 0,
      totalRevenue: 0
    }
  },
  methods: {
    async GetRevenueByMonth() {
      const response = await RevenueByMonth()
      if (response.status === 200 && response.data.isSuccess) {
        this.revenueByMonth = response.data.data
      }
    },
    async GetYearlyRevenue() {
      const response = await YearlyRevenue()
      if (response.status === 200 && response.data.isSuccess) {
        this.yearlyRevenue = response.data.data
      }
    },
    async GetTotalRevenue() {
      const response = await TotalRevenue()
      if (response.status === 200 && response.data.isSuccess) {
        this.totalRevenue = response.data.data
      }
    }
  },
  mounted() {
    const setDashboardLayout = inject('setDashboardLayout')
    setDashboardLayout(true)
    this.isReady = true
    this.GetRevenueByMonth()
    this.GetYearlyRevenue()
    this.GetTotalRevenue()
  }
}
</script>
