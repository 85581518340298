<template>
  <teleport to="#breadcrumb-wrapper" v-if="isReady">
    <router-link :to="{ name: 'adminDashboard' }"> Dashboard </router-link>
    <ChevronRightIcon class="breadcrumb__icon" />
    <a href="" class="breadcrumb--active">Customers</a>
  </teleport>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Customers</h2>
    </div>
    <entitiesTable
      :controller="'users'"
      :columns="columns"
      :title="'Customers'"
      :customParams="params"
      :overrideForm="'customersForm'"
    ></entitiesTable>
  </div>
</template>

<script>
import entitiesTable from '@/components/entity-table/EntityTable.vue'
import breadcrumbMixin from '@/mixins/BreadcrumbMixin'
import { mapActions } from 'vuex'

export default {
  components: {
    entitiesTable: entitiesTable
  },
  mixins: [breadcrumbMixin],
  data() {
    return {
      filters: [
        { key: 'firstName', value: 'First Name' },
        { key: 'lastName', value: 'Last Name' }
      ],
      params: [
        {
          roles: 2,
          isActive: 1
        }
      ],
      columns: [
        {
          formatter: 'responsiveCollapse',
          width: 40,
          minWidth: 30,
          hozAlign: 'center',
          resizable: false,
          headerSort: false
        },

        // For HTML table
        {
          title: 'FULL NAME',
          minWidth: 200,
          responsive: 0,
          field: 'fullname',
          vertAlign: 'middle',
          print: false,
          download: false
        },
        {
          title: 'EMAIL',
          minWidth: 200,
          responsive: 0,
          field: 'email',
          vertAlign: 'middle',
          print: false,
          download: false
        },
        {
          title: 'PHONE NUMBER',
          minWidth: 200,
          responsive: 1,
          field: 'phoneNumber',
          vertAlign: 'middle',
          print: false,
          download: false
        },
        {
          title: 'CITY',
          minWidth: 200,
          responsive: 1,
          field: 'city.name',
          vertAlign: 'middle',
          print: false,
          download: false
        },
        {
          title: 'PROVINCE',
          minWidth: 200,
          responsive: 1,
          field: 'province.name',
          vertAlign: 'middle',
          print: false,
          download: false
        },
        {
          title: 'CREATED AT',
          minWidth: 200,
          responsive: 1,
          field: 'createdAtString',
          vertAlign: 'middle',
          print: false,
          download: false
        },
        // For print format
        {
          title: 'FULL NAME',
          field: 'fullname',
          visible: false,
          print: true,
          download: true
        },
        {
          title: 'EMAIL',
          field: 'email',
          visible: false,
          print: true,
          download: true
        },
        {
          title: 'PHONE NUMBER',
          field: 'phoneNumber',
          visible: false,
          print: true,
          download: true
        },
        {
          title: 'CITY',
          field: 'city.name',
          visible: false,
          print: true,
          download: true
        },
        {
          title: 'PROVINCE',
          field: 'province.name',
          visible: false,
          print: true,
          download: true
        },
        {
          title: 'CREATED AT',
          field: 'createdAtString',
          visible: false,
          print: true,
          download: true
        }
      ]
    }
  },
  methods: {
    ...mapActions('slideover', ['ChangeShownState'])
  }
}
</script>
