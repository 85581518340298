const controllerName = 'invoices'

function Get(id) {
  return window.axios.get(`api/${controllerName}/${id}`)
}

function GetCustomerInvoices() {
  return window.axios.get(`api/${controllerName}/customer`)
}

function Put(id, formModel) {
  return window.axios.put(`api/${controllerName}/${id}`, formModel)
}

function Post(formModel, courier, duration, cartVM) {
  const request = {
    customer: formModel,
    courier: courier,
    service: duration,
    cartItemVMs: cartVM
  }

  return window.axios.post(`api/${controllerName}`, request)
}

function Process(id) {
  return window.axios.put(`api/${controllerName}/process/${id}`)
}

function PutReceiptNumber(id, receiptNumber) {
  const request = {
    id: id,
    receiptNumber: receiptNumber
  }
  return window.axios.put(`api/${controllerName}/receipt-number/${id}`, request)
}

export { Get, GetCustomerInvoices, Put, PutReceiptNumber, Post, Process }
