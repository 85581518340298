export default {
  CHECKOUT: {
    TITLE: 'Checkout',
    PRODUCTS: 'Product | Products',
    SUMMARY: 'Summary',
    TOTAL_PRICE: 'Total Price',
    DISCOUNT: 'Discount',
    SHIPPING: 'Shipping Cost',
    TOTAL: 'Total'
  }
}
