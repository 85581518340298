export default {
  data() {
    return {
      isReady: false
    }
  },
  mounted() {
    this.isReady = true
  }
}
