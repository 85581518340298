export default {
  CARE_MANUAL: {
    TITLE: 'Hikarusa Care Instruction',
    CARE_MANUAL: {
      TITLE: 'Cara Merawat Hikarusa',
      POINT1: 'Jaga tetap kering',
      POINT2: 'Tidak perlu disetrika',
      POINT3: 'Jemur dibawah terik matahari',
      POINT4: 'Dibersihkan dengan di-vacuum',
      POINT5: 'Keep it Fresh',
      POINT6: 'Cuci menggunakan tangan',
      CONTENT1:
        'Wajib untuk menjaga bantal guling Hikarusa tetap kering, jauhi dari air, tempat yang lembab dan dingin. Jika anak mudah berkeringat sebaiknya selalu di lap kering setiap pemakaian, menggunakan tissue atau lap kering.',
      CONTENT2:
        'Setelah produk Hikarusa kering boleh dipasang kembali ke bantal/guling tanpa harus di setrika, kami tidak merekomendasikan menggunakan setrika jenis apapun ( setrika uap/kering )',
      CONTENT3:
        'Selalu jemur Hikarusa mu setidaknya seminggu sekali, boleh bersama dengan sarung ataupun dilepas agar tetap kering, pastikan juga sirkulasi udara yang baik saat dikeringkan.',
      CONTENT4:
        'Cukup dibersihkan dengan vaccum kering atau ditepuk tepuk dengan tangan (lihat video youtube untuk lebih jelas).',
      CONTENT5:
        'Jauhkan dari benda yang berbau tajam seperti makanan/parfum agar bau tidak menempel dengan Hikarusa. Boleh menggunakan bed spray/linen spray dengan jumlah tidak terlalu banyak menghindari lembab, essential oil boleh diteteskan tapi tidak direkomendasikan karena warna oil dapat membekas.',
      CONTENT6:
        'Agar menjaga bentuk sarung Hikarusa tidak berubah, kami sarankan mencuci sarung Hikarusa menggunakan tangan saja, dikeringkan dengan dibentangkan. Boleh dikeringkan dengan dryer dengan suhu rendah. Kami rekomendasikan menggunakan detergent khusus baby.'
    },
    WASHING_MANUAL: {
      TITLE: 'Cara Mencuci Hikarusa',
      INTRO:
        'Jika produk Hikarusa mu terutama bantal/guling terkena kotor berupa gumoh/ompol/pipis atau noda yang cukup banyak, berikut saran pencucian dari kami',
      CONTENT1:
        'Segera Lepaskan sarung dari BANTAL/GULING yang terkena noda pisahkan dan jemur jika noda terkena/menyerap sedikit ke dalam.',
      CONTENT2:
        'Bersihkan dengan tissue basah/kain lap kering di bagian yang terkena noda jika masih ringan.',
      CONTENT3:
        'Jika noda sudah menyerap banyak ke dalam BANTAL/GULING sebaiknya dicuci menggunakan air dan detergent cair ( dengan catatan isian dapat menggumpal dan tidak dapat kembali ke bentuk semula).',
      CONTENT4:
        'Pastikan mencuci hingga noda bersih tanpa menginggalkan bekas, dan merekomendasikan mencuci dengan tangan secara perlahan.',
      CONTENT5:
        'Setelah bersih, keringkan dengan cara dijemur dibawah terik matahari secara langsung dan memiliki sirkulasi angin yang cukup baik.',
      CONTENT6:
        'Direkomendasikan untuk menjemur dengan posisi terbentang (tidak dicapit/digantung) agar menjaga bentuk bantal/guling.',
      CONTENT7:
        'Setelah isi BANTAL/GULING sudah kering seluruh isi dan permukaan, maka Hikarusa boleh dipakai Kembali, jika terasa masih lembab atau basah kami sarankan untuk dijemur beberapa hari.',
      TNC: `*Jika bentuk isian bantal sudah berubah, masih bisa dipakai jika pengguna masih nyaman.
        <br/>*Isian dapat menggumpal jika terkena basah, dan sulit untuk Kembali ke bentuk semula`
    }
  }
}
