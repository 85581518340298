export default {
  CART: {
    TITLE: 'Cart',
    SUBTOTAL: 'Subtotal',
    CARTNOTIF: 'Shipping, taxes, and discount codes calculated at checkout.',
    CHECKOUT: 'Checkout',
    VARIANTS: 'Variants',
    SIZES: 'Sizes',
    COLORS: 'Colors',
    NO_ITEM: 'No item in cart'
  }
}
