const controllerName = 'carts'

function Revalidate(userId, cart) {
  const request = {
    customerId: userId,
    revalidateCartVMs: cart
  }

  return window.axios.post(`api/${controllerName}`, request)
}

export { Revalidate }
