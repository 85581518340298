import { useToast } from 'vue-toastification'
import { Revalidate } from '@/core/services/entities/cart.service.js'

export const UPDATE_CART = 'UpdateCart'
export const ADD_CART_ITEM = 'AddItemToCart'
export const DEDUCT_CART_ITEM = 'DeductItemToCart'
export const REMOVE_CART_ITEM = 'RemoveCartItem'
export const REVALIDATE_CART = 'RevalidateCart'
export const MODIFY_IS_LOADING = 'ModifyIsLoading'
export const EMPTY_CART = 'EmptyCartItems'

const state = {
  cart: [],
  lastModified: null,
  isLoading: false
}

const getters = {
  cart: state => state.cart,
  isLoading: state => state.isLoading,
  isCartValid(state) {
    const emptyStockItem = state.cart.filter(x => x.lastStock == 0)
    if (emptyStockItem.length > 0) {
      return false
    }

    return true
  }
}

const mutations = {
  [ADD_CART_ITEM](state, productVM) {
    // search if the item exists in the cart
    const searchArray = state.cart.filter(
      x => x.productDetailId == productVM.productDetailId
    )

    const toast = useToast()
    if (searchArray.length > 0) {
      // If exist, check the added Qty with the avilable stock
      const cartItem = searchArray[0]
      if (cartItem.quantity + productVM.quantity > productVM.lastStock) {
        toast.error(
          `You already have ${cartItem.quantity} of this item in your cart. Adding ${productVM.quantity} to the cart will exceed the available stock`,
          {
            timeout: 3500
          }
        )
      } else {
        cartItem.quantity += productVM.quantity
        updateLastModified(state)
        toast.success('Product added to cart', {
          timeout: 2000
        })
      }

      cartItem.lastStock = productVM.lastStock
    } else {
      // If not, just create new item in cart
      state.cart.push(productVM)
      toast.success('Product added to cart', {
        timeout: 2000
      })
      updateLastModified(state)
    }
  },
  [DEDUCT_CART_ITEM](state, productId) {
    const targetItem = state.cart.filter(x => x.productDetailId == productId)
    if (targetItem.length > 0) {
      const target = targetItem[0]
      if (target.quantity > 0) {
        target.quantity--
      } else {
        removeItemFromCart(state, productId)
      }
    }
  },
  [REMOVE_CART_ITEM](state, productId) {
    removeItemFromCart(state, productId)
  },
  [MODIFY_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading
  },
  [UPDATE_CART](state, cart) {
    cart.forEach(element => {
      const item = state.cart.filter(
        x => x.productDetailId === element.productDetailId
      )[0]
      item.color = element.color
      item.lastStock = element.lastStock
      item.isActive = element.isActive
      item.price = element.price
      item.quantity = element.quantity
      item.size = element.size
      item.thumb = element.thumb
      item.variant = element.variant
      item.discount = element.discount
      item.name = element.name
      item.weight = element.weight
    })
  },
  [EMPTY_CART](state) {
    state.cart = []
  }
}

const actions = {
  [ADD_CART_ITEM]({ commit }, productVM) {
    commit(ADD_CART_ITEM, productVM)
  },
  [DEDUCT_CART_ITEM]({ commit }, productId) {
    commit(DEDUCT_CART_ITEM, productId)
  },
  [REMOVE_CART_ITEM]({ commit }, productId) {
    commit(REMOVE_CART_ITEM, productId)
  },
  async [REVALIDATE_CART]({ state, commit, rootState }) {
    commit(MODIFY_IS_LOADING, true)
    const response = await Revalidate(rootState.auth.user?.id, state.cart)
    if (response.status === 200) {
      commit(UPDATE_CART, response.data.data)
    }
    commit(MODIFY_IS_LOADING, false)
  },
  [EMPTY_CART]({ commit }) {
    commit(EMPTY_CART)
  }
}

function removeItemFromCart(state, productId) {
  const targetItem = state.cart.filter(x => x.productDetailId == productId)
  if (targetItem.length > 0) {
    const targetIndex = state.cart.indexOf(targetItem[0])
    state.cart.splice(targetIndex, 1)
  }
}

function updateLastModified(state) {
  state.lastModified = window.moment(new Date()).format('DD-MM-YYYY HH:mm')
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
