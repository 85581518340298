<template>
  <teleport to="#breadcrumb-wrapper" v-if="isReady">
    <router-link :to="{ name: 'adminDashboard' }"> Dashboard </router-link>
    <ChevronRightIcon class="breadcrumb__icon" />
    <a href="" class="breadcrumb--active">Business Partners</a>
  </teleport>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Business Partners</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          class="btn btn-primary shadow-md mr-2"
          @click="openSlideoutPanel"
        >
          Add New Business Partner
        </button>
      </div>
    </div>
    <entitiesTable
      :filters="filters"
      :controller="'businessPartners'"
      :columns="columns"
      :title="'Business Partner'"
    ></entitiesTable>
  </div>
</template>

<script>
import entitiesTable from '@/components/entity-table/EntityTable.vue'
import breadcrumbMixin from '@/mixins/BreadcrumbMixin'
import { mapActions } from 'vuex'

export default {
  components: {
    entitiesTable: entitiesTable
  },
  mixins: [breadcrumbMixin],
  data() {
    return {
      filters: [
        { key: 'name', value: 'Name' },
        { key: 'description', value: 'Description' }
      ],
      columns: [
        {
          formatter: 'responsiveCollapse',
          width: 40,
          minWidth: 30,
          hozAlign: 'center',
          resizable: false,
          headerSort: false
        },

        // For HTML table
        {
          title: 'NAME',
          minWidth: 200,
          responsive: 0,
          field: 'name',
          vertAlign: 'middle',
          print: false,
          download: false
        },
        {
          title: 'DESCRIPTION',
          minWidth: 200,
          responsive: 0,
          field: 'description',
          vertAlign: 'middle',
          print: false,
          download: false
        },
        {
          title: 'TYPE',
          minWidth: 200,
          responsive: 0,
          field: 'type',
          vertAlign: 'middle',
          print: false,
          download: false
        },
        // For print format
        {
          title: 'NAME',
          field: 'name',
          visible: false,
          print: true,
          download: true
        },
        {
          title: 'DESCRIPTIONS',
          field: 'descriptions',
          visible: false,
          print: true,
          download: true
        },
        {
          title: 'TYPE',
          field: 'type',
          visible: false,
          print: true,
          download: true
        }
      ]
    }
  },
  methods: {
    ...mapActions('slideover', ['ChangeShownState']),
    openSlideoutPanel() {
      this.ChangeShownState({
        isShown: true,
        title: 'Add Business Partner',
        component: 'businessPartnersForm'
      })
    }
  }
}
</script>
