export default {
  ACCOUNT_DETAIL: {
    TITLE: 'Account Detail',
    TAB: {
      ACCOUNT_INFORMATION: {
        TITLE: 'Account Information'
      },
      CHANGE_PASSWORD: {
        TITLE: 'Change Password'
      },
      TRANSACTION_HISTORY: {
        TITLE: 'Transaction History',
        ERROR_GET: 'Failed to fetch your transaction history!'
      }
    }
  }
}
