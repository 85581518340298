export default {
  LOGIN: {
    SUBTITLE1: 'Welcome to',
    SUBTITLE2:
      'Please fill the form with your email and password to proceed further into our website, if you have problem please contact our team',
    REMEMBER_ME: 'Remember Me',
    FORGOT_PASSWORD: 'Forgot Password'
  },
  REGISTRATION: {
    SUBTITLE:
      'Please fill the form to create your account, if you have problem please contact our team'
  }
}
