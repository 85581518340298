<template>
  <teleport to="#breadcrumb-wrapper" v-if="isReady">
    <router-link :to="{ name: 'adminDashboard' }"> Dashboard </router-link>
    <ChevronRightIcon class="breadcrumb__icon" />
    <a href="" class="breadcrumb--active">Invoices</a>
  </teleport>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Invoices</h2>
    </div>
    <entitiesTable
      :isViewable="true"
      :isEditable="false"
      :isDeletable="false"
      :onView="viewInvoice"
      :controller="'invoices'"
      :columns="columns"
      :title="'Invoices'"
      :filters="filters"
    ></entitiesTable>
  </div>
  <receiptNumberModal
    :inputReceiptNumber="receiptNumber"
    :invoiceId="receiptInvoiceId"
  ></receiptNumberModal>
</template>

<script>
import receiptNumberModal from './ReceiptNumberModal.vue'
import entitiesTable from '@/components/entity-table/EntityTable.vue'
import breadcrumbMixin from '@/mixins/BreadcrumbMixin'
import { mapActions } from 'vuex'

export default {
  components: {
    entitiesTable: entitiesTable,
    receiptNumberModal: receiptNumberModal
  },
  mixins: [breadcrumbMixin],
  data() {
    const _vue = this
    return {
      filters: [{ key: 'documentNo', value: 'Document No' }],
      receiptInvoiceId: '',
      receiptNumber: '',
      columns: [
        {
          formatter: 'responsiveCollapse',
          width: 40,
          minWidth: 30,
          hozAlign: 'center',
          resizable: false,
          headerSort: false
        },

        // For HTML table
        {
          title: 'CREATED AT',
          minWidth: 200,
          responsive: 0,
          field: 'createdAt',
          vertAlign: 'middle',
          print: false,
          download: false
        },
        {
          title: 'DOCUMENT NO',
          minWidth: 200,
          responsive: 0,
          field: 'documentNo',
          vertAlign: 'middle',
          print: false,
          download: false
        },
        {
          title: 'CUSTOMER',
          minWidth: 200,
          responsive: 0,
          field: 'customer.fullname',
          vertAlign: 'middle',
          print: false,
          download: false
        },
        {
          title: 'RECEIPT NUMBER',
          minWidth: 200,
          responsive: 0,
          field: 'receiptNumber',
          vertAlign: 'middle',
          print: false,
          download: false
        },
        {
          title: 'PAID',
          minWidth: 200,
          responsive: 0,
          field: 'isPaymentSuccess',
          vertAlign: 'middle',
          hozAlign: 'center',
          print: false,
          download: false,
          formatter(cell) {
            return `<div class="flex items-center lg:justify-center ${
              cell.getData().isPaymentSuccess == true
                ? 'text-theme-20'
                : 'text-theme-21'
            }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().status
                }
              </div>`
          }
        },
        {
          title: '',
          headerSort: false,
          formatter(cell) {
            let title
            if (cell.getData().receiptNumber) {
              title = 'Edit Receipt'
            } else {
              title = 'Input Receipt'
            }

            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3 btn-edit" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> ${title}
                </a>
              </div>`)
            cash(a).on('click', function () {
              _vue.receiptInvoiceId = cell.getRow().getData().id
              if (cell.getData().receiptNumber) {
                _vue.receiptNumber = cell.getData().receiptNumber
              } else {
                _vue.receiptNumber = ''
              }
              cash('#input-receipt').modal('toggle')
            })
            return a[0]
          }
        },
        // For print format
        {
          title: 'CREATED AT',
          field: 'createdAt',
          visible: false,
          print: true,
          download: true
        },
        {
          title: 'DOCUMENT NO',
          field: 'documentNo',
          visible: false,
          print: true,
          download: true
        },
        {
          title: 'CUSTOMER',
          field: 'customer.fullname',
          visible: false,
          print: true,
          download: true
        },
        {
          title: 'RECEIPT NUMBER',
          field: 'receiptNumber',
          visible: false,
          print: true,
          download: true
        },
        {
          title: 'STATUS',
          field: 'isPaymentSuccess',
          visible: false,
          print: true,
          download: true
        }
      ]
    }
  },
  methods: {
    ...mapActions('slideover', ['ChangeShownState']),
    viewInvoice(id) {
      this.$router.push({
        name: 'adminInvoiceDetail',
        params: { id: id }
      })
    }
  }
}
</script>
